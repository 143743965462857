import React from "react"
import { Link } from "gatsby"
import NavbarBurger from "../components/NavBarBurger"
import CatNavBar from "../components/CatNavBar"
// import NavbarItem from "./NavbarItem";
// import NavbarBurger from "./NavbarBurger";

export default class Navbar extends React.Component {
  state = {
    activeMenu: false,
    activeSelector: false,
  }
  
  toggleMenu = () => {
    this.props.globalState.updateState({menuActif: !this.state.activeMenu})
    this.setState({
      activeMenu: !this.state.activeMenu,
    }) 
  }
  toggleSelector = () => {
    this.setState({
      activeSelector: !this.state.activeSelector,
    })
  }

  render() {
    const pageContext = this.props.pageContext
    const link = pageContext.language ? pageContext.language : false
    const actualLang = pageContext.language ? pageContext.language.toUpperCase() : false
    return (
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to={link ? ("/"+link+"/") : "/fr/"} className="navbar-item logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="217" height="52" viewBox="0 0 217 52" fill="none">
              <path d="M94.2788 2.38151C95.0372 2.36478 95.791 2.50456 96.4937 2.79221C97.1965 3.07987 97.8332 3.50932 98.3648 4.05411L100.103 2.38151C98.706 0.815991 96.6299 0 94.1504 0C89.5142 0 86.125 3.15689 86.125 7.52485C86.125 11.8928 89.5142 15.0534 94.1101 15.0534C96.2044 15.0534 98.3648 14.4257 99.9127 13.2073V6.84178V6.61655H94.3521V9.0276H97.3048V11.9076C96.3706 12.4181 95.3195 12.6728 94.2568 12.646C91.1244 12.646 88.8979 10.475 88.8979 7.50269C88.8979 4.47503 91.1244 2.38151 94.2788 2.38151Z" fill="white"/>
              <path d="M119.159 7.31728L114.82 0.1875H111.875L117.7 9.70249V14.8237H120.451V9.66187L126.253 0.1875H123.543L119.159 7.31728Z" fill="white"/>
              <path d="M154.873 0.1875H152.607L146.826 9.89079L140.939 0.1875H138.676V14.8237H141.298V5.24961L146.169 13.1511H147.398L152.269 5.12406L152.291 14.8237H154.895L154.873 0.1875Z" fill="white"/>
              <path d="M63.4121 0.269531H74.3278V2.55874H66.0017V6.34332H73.3962V8.62883H66.0017V12.5389H74.4379V14.8245H63.4121V0.269531Z" fill="white"/>
              <path d="M0.679688 29.3789H3.61402L8.92883 46.7326L14.3097 29.3789H16.5104L21.9243 46.6255L27.2024 29.3789H30.0414L23.2741 51.311H20.6698L15.388 34.3635L10.0732 51.311H7.43232L0.679688 29.3789Z" fill="#00A7B5"/>
              <path d="M40.8867 29.3789H57.0879V31.9635H43.6927V38.7093H55.507V41.2939H43.6927V48.719H57.4217V51.3036H40.8867V29.3789Z" fill="#00A7B5"/>
              <path d="M69.377 29.3789H72.1829V48.6858H84.287V51.311H69.377V29.3789Z" fill="#00A7B5"/>
              <path d="M95.0879 29.3789H97.8938V48.6858H109.998V51.311H95.0879V29.3789Z" fill="#00A7B5"/>
              <path d="M120.803 29.3789H129.749C134.884 29.3789 137.539 31.6386 137.539 36.025C137.539 40.4114 134.899 42.6711 129.749 42.6711H123.609V51.311H120.803V29.3789ZM129.716 40.0865C133.215 40.0865 134.7 38.857 134.7 36.025C134.7 33.193 133.215 31.9635 129.716 31.9635H123.609V40.0865H129.716Z" fill="white"/>
              <path d="M153.352 29.3789H155.894L165.064 51.311H162.06L159.881 46.0938H149.244L147.065 51.311H144.16L153.352 29.3789ZM158.799 43.4686L154.573 33.001L150.348 43.4686H158.799Z" fill="white"/>
              <path d="M174.348 49.483V46.3187C176.182 48.0799 179.035 49.0769 182.05 49.0769C185.649 49.0769 187.629 47.6812 187.629 45.3218C187.629 43.4757 186.375 42.3311 183.371 41.6997L179.971 40.9612C175.976 40.0972 174.425 38.0074 174.425 35.2456C174.425 31.3576 177.462 28.9688 182.644 28.9688C185.384 28.9688 187.893 29.6334 189.346 30.7964V33.8536C187.761 32.3767 185.252 31.5939 182.611 31.5939C179.211 31.5939 177.297 32.9232 177.297 35.1164C177.297 36.7447 178.353 37.808 181.39 38.4394L184.856 39.1779C188.818 40.0086 190.501 42.1317 190.501 45.1926C190.501 49.3464 187.431 51.7058 182.065 51.7058C178.903 51.7094 176.031 50.9119 174.348 49.483Z" fill="white"/>
              <path d="M200.527 49.483V46.3187C202.361 48.0799 205.215 49.0769 208.23 49.0769C211.828 49.0769 213.809 47.6812 213.809 45.3218C213.809 43.4757 212.554 42.3311 209.55 41.6997L206.15 40.9612C202.156 40.0972 200.604 38.0074 200.604 35.2456C200.604 31.3576 203.641 28.9688 208.824 28.9688C211.564 28.9688 214.073 29.6334 215.525 30.7964V33.8536C213.941 32.3767 211.432 31.5939 208.791 31.5939C205.391 31.5939 203.476 32.9232 203.476 35.1164C203.476 36.7447 204.533 37.808 207.57 38.4394L211.036 39.1779C214.997 40.0086 216.681 42.1317 216.681 45.1926C216.681 49.3464 213.611 51.7058 208.245 51.7058C205.083 51.7094 202.211 50.9119 200.527 49.483Z" fill="white"/>
            </svg>
          </Link>
          <NavbarBurger
            active={this.state.activeMenu}
            toggleMenu={this.toggleMenu}
          />
        </div>
        <div
          className={`navbar-menu navbar-end ${
            this.state.activeMenu ? "is-active" : ""
            }`}
        >
          <CatNavBar pageContext={pageContext} />
          {pageContext.language ? 
          (<div className="navbar-item"> 
            <div className={this.state.activeSelector ? "dropdown is-active" : ""} onClick={this.toggleSelector} onKeyDown={this.toggleSelector} role="button" tabIndex={0}>
              <div className="dropdown-trigger">
                <button className="button lang-select" aria-haspopup="true" aria-controls="dropdown-lang-select">
                  <span>{actualLang}</span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-lang-select" role="menu">
                <div className="dropdown-content">
                  <Link to="/fr/" className="navbar-item is-fr">
                    FR
                  </Link>
                  <Link to="/en/" className="navbar-item is-en">
                    EN
                  </Link>
                  <Link to="/nl/" className="navbar-item is-nl">
                    NL
                  </Link>
                </div>
              </div>
            </div>
          </div>) 
          : ""}
          {pageContext.navLink ? 
          (<a href={pageContext.navLink} className="navbar-item cta">
            {pageContext.navLinkText}
          </a>) 
          : ""}
        </div>
      </nav>
    )
  }
}
